import React, { useState, useEffect, useRef, useCallback, memo } from "react";
import { Button } from "react-bootstrap";
import { Form as FormFormik, Formik } from "formik";
import {
  FormikTextInput,
  FormikSelect as Select,
  FormikCheckBox as CheckBox,
} from "../utils/forms";
import * as Yup from "yup";
import ModalCompanies from "../SearchModals/ModalCompanies";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {
  getFundsBy,
  getVoteProportion,
  getVoteProportionAll,
  searchCompanies,
} from "../../utils/reqs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useSASBLists } from "../../contexts/subjectsProvider";
import { FormControl, Input, Typography } from "@mui/material";
import { filterStatsByFormDates } from "../../utils/utils";
import InfoTooltip from "../InfoTooltip";

/**
 * Component of form to search logs
 * @param {Object} props
 * @param {function} props.getESGforAll - Function to retrieve the ESG information of a fund
 * @param {function} props.setAllFunds - Function to update de state of the hook allfunds
 * @returns the rendered component
 * @author valeriaxeleva
 */
const SearchFundsForm = ({
  selectedFunds,
  activeGraph,
  setAllFunds,
  getESGforAll,
  setCsvVotesParams,
  setTotalVotesFund,
  setLoadingChart,
  setVoteProportionAllLoading,
  reAsignIds,
  setProposalsVoteAll,
  setFormOptions,
  user,
}) => {
  const { SASBProposalsList } = useSASBLists();
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [fundsList, setFundsList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [disableSelect, setDisableSelect] = useState(false);
  const formikRef = useRef();
  const [selectedCompanyQuery, setSelectedCompanyQuery] = useState({});
  const resetSelectedCompaniesinModal = useRef();
  const [subjectsProposals, setSubjectsProposals] = useState([]);

  useEffect(() => {
    const filter = { index: ["S&P 500","S&P/TSX 60", "S&P/ASX 50"] };
    searchCompanies(1, filter, 1000)
      .then((res) => {
        const options = res.data.companies.map((option) => {
          const firstLetter = option.name[0].toUpperCase();
          return {
            firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
            id: option._id,
            ...option,
          };
        });
        setCompanyList(options);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [setCompanyList]);

  useEffect(() => {
    const params = {
      hierarchy: "manager",
    };
    getFundsBy(params, 5000, 1, "name")
      .then((res) => {
        const options = res.funds.map((option) => {
          const firstLetter = option.name[0].toUpperCase();
          return {
            firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
            id: option._id,
            ...option,
          };
        });

        setFundsList(options);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [setFundsList]);

  useEffect(() => {
    if (activeGraph !== 1) {
      setSelectedCompanyQuery({});
      formikRef.current.setFieldValue("company", "");
    }
    if (activeGraph !== 2) {
      formikRef.current.setFieldValue("keywords", "");
    }
  }, [activeGraph]);

  useEffect(() => {
    if (selectedFunds.length > 0)
      formikRef.current.setFieldValue("funds", selectedFunds);
    selectedFunds.length > 5 ? setDisableSelect(true) : setDisableSelect(false);
  }, [selectedFunds, setAllFunds]);

  useEffect(() => {
    const proposalSubjectsOrdered = [];
    for (const subjectParent of SASBProposalsList) {
      let subjectParentName = subjectParent.name;
      const childSubjects = [];
      for (const subjectChild of subjectParent.subs) {
        childSubjects.push(subjectChild);
      }
      proposalSubjectsOrdered.push({
        name: subjectParentName,
        subs: childSubjects,
      });
    }
    setSubjectsProposals(proposalSubjectsOrdered);
  }, [SASBProposalsList]);

  const handleSubmitMain = (vals, { setSubmitting }) => {
    setTimeout(() => {
      setSubmitting(false);
    }, 400);
    const valuesToSubmit = {};
    if (vals.endDate) {
      vals.endDate.$d.setMonth(11);
      vals.endDate.$d.setDate(31);
      valuesToSubmit.endDate = vals.endDate.$d;
    }
    if (vals.startDate) valuesToSubmit.startDate = vals.startDate;
    valuesToSubmit.funds = vals.funds;
    setFormOptions({
      checkedFM: vals.financiallyMaterial,
      checkedSH: vals.proponent,
      startDate: vals.startDate,
      endDate: vals.endDate,
    });
    setAllFunds(valuesToSubmit.funds);
    getESGforAll(valuesToSubmit);
  };

  const handleSubmit = (values) => {
    const valuesForRequest = {};
    setAllFunds(values.funds);
    if (values.keywords) valuesForRequest["keywords"] = values.keywords;
    if (values.startDate && values.startDate !== "default") {
      valuesForRequest["startDate"] = values.startDate.$d
        .toISOString()
        .split("T")[0];
    }
    if (values.endDate && values.endDate !== "default") {
      values.endDate.$d.setMonth(11);
      values.endDate.$d.setDate(31);
      valuesForRequest["endDate"] = values.endDate.$d
        .toISOString()
        .split("T")[0];
    }
    if (values.financiallyMaterial && values.financiallyMaterial !== "false")
      valuesForRequest["financiallyMaterial"] = values.financiallyMaterial;
    if (values.proponent && values.proponent !== "false")
      valuesForRequest["proponent"] = "shareholder";
    if (selectedCompanyQuery?.name)
      valuesForRequest["company"] = selectedCompanyQuery._id;

    if (
      values.subjects &&
      values.subjects.length > 0 &&
      values.subjects !== "none"
    ) {
      if (values.subjects.indexOf("   ") > -1) {
        const parentSubjectSelected = subjectsProposals.find(
          (subjectParent) => subjectParent.name === values.subjects.trim()
        );
        valuesForRequest["subjects"] = parentSubjectSelected.subs.slice();
      } else {
        valuesForRequest["subjects"] = [values.subjects];
      }
    }
    let idsFunds = values.funds.map((f) => f._id);
    setCsvVotesParams(
      JSON.parse(JSON.stringify({ ...valuesForRequest, fund: idsFunds }))
    );
    setLoadingChart(true);

    //Info for graphs
    const promises = selectedFunds.map((fund) => {
      const body = { ...valuesForRequest, fund: fund.id };
      return getVoteProportion(body);
    });

    Promise.allSettled(promises)
      .then((results) => {
        const allstats = results.map((result, i) => {
          const { statistics, name, message } = result.value;
          let obj = {};
          if (statistics?.length > 0) {
            obj = { statistics, name };
          } else {
            obj = { statistics: [], name, message };
          }
          return obj;
        });

        allstats.sort((a, b) =>
          !a.message ? -1 : !b.message ? 1 : a.message.localeCompare(b.message)
        );
        const newAllstats = filterStatsByFormDates(values, null, allstats);
        setTotalVotesFund(newAllstats);
      })
      .catch((error) => {
        console.log("error", error);
        const { response } = error;
        alert(!response?.data ? error.message : response.data.message);
      })
      .finally(() => {
        setLoadingChart(false);
      });

    //Info for tables
    getVotes(valuesForRequest);
  };

  const getVotes = (values) => {
    setVoteProportionAllLoading(true);
    const promises2 = selectedFunds.map((fund) => {
      const body = { ...values, fund: fund.id };
      return getVoteProportionAll(body);
    });

    Promise.allSettled(promises2)
      .then((results) => {
        const allvotes = results.map((result) => {
          const { proposals, message, id, ...paginationData } = result.value;
          let new_proposals = [];
          if (proposals !== undefined) new_proposals = reAsignIds(proposals);
          return { proposals: new_proposals, message, id, paginationData };
        });

        setProposalsVoteAll(allvotes);
      })
      .catch((error) => {
        console.log("error", error);
        const { response } = error;
        //this never happens
        alert(!response?.data ? error.message : response.data.message);
      })
      .finally(() => {
        setVoteProportionAllLoading(false);
      });
  };

  const fundsStatsSchema = Yup.object({
    funds: Yup.array().required("Required"),
  });

  const checkDisable = useCallback(() => {
    return disableSelect;
  }, [disableSelect]);

  return (
    <>
      <Formik
        initialValues={{
          funds: [],
          company: "",
          keywords: "",
          startDate: null,
          endDate: null,
          financiallyMaterial: false,
          proponent: false,
          subjects: "",
        }}
        validationSchema={fundsStatsSchema}
        onSubmit={
          activeGraph === 0
            ? handleSubmitMain
            : (values, { setSubmitting }) => {
                setTimeout(() => {
                  setSubmitting(false);
                }, 400);
                handleSubmit(values);
              }
        }
        innerRef={formikRef}
      >
        {({
          values,
          resetForm,
          dirty,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
        }) => (
          <FormFormik id="logsCompanyForm" className="sideForm">
            <div className="form_xl">
              <Autocomplete
                multiple
                name="funds"
                id="tags-filled"
                disableClearable
                options={fundsList.sort(
                  (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                )}
                groupBy={(option) => option.firstLetter}
                getOptionLabel={(option) => option.name}
                value={values.funds}
                filterSelectedOptions
                getOptionDisabled={checkDisable}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.name}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                onChange={(e, chips) => {
                  setAllFunds(() => [...chips]);
                  setFieldValue("funds", [...chips]);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      backgroundColor: "white.main",
                      borderBottom: (theme) => `4px solid ${theme.palette.primary.light}`,
                      width: "90%",
                      borderRadius: "3px 3px 0px 0px",
                      fontWeight: "500"
                    }}
                    {...params}
                    variant="standard"
                    label={"Investors"}
                    placeholder=""
                  />
                )}
              />
            </div>
            <Typography
              component="p"
              variant="h4"
              color="white.main"
              sx={{ 
                 margin: "20px 0 10px 0" }}
            >
              Proxy Record{" "}
              <InfoTooltip
                tooltipText={
                  "Select the date range of the proxy votes you want to see."
                }
                color="white.main"
              />
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={["year"]}
                value={values.startDate || null}
                name="startDate"
                maxDate={values.endDate || new Date().setMonth(0)}
                minDate={new Date("2015-01-02")}
                defaultValue={null}
                onChange={(newValue) => setFieldValue("startDate", newValue)}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <FormControl
                    className="formInputMui"
                    variant="standard"
                    ref={inputRef}
                  >
                    <Typography component="label" color="white.main">From</Typography>
                    <Input {...InputProps} {...inputProps} />
                  </FormControl>
                )}
              />
              <DatePicker
                views={["year"]}
                value={values.endDate || null}
                name="endDate"
                maxDate={new Date().setMonth(11)}
                minDate={values.startDate || new Date("2015-12-31")}
                onChange={(newValue) => setFieldValue("endDate", newValue)}
                defaultValue={null}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <FormControl
                    className="formInputMui"
                    variant="standard"
                    ref={inputRef}
                  >
                    <Typography component="label" color="white.main">To</Typography>
                    <Input {...InputProps} {...inputProps} />
                  </FormControl>
                )}
              />
            </LocalizationProvider>
            {activeGraph > 0 ? (
              <>
                {activeGraph === 2 ? (
                  <FormikTextInput
                    name="keywords"
                    type="text"
                    placeholder="Keywords"
                    style={{
                      width: "90%",
                      backgroundColor: "white",
                      margin: "14px 0",
                    }}
                  />
                ) : activeGraph === 1 ? (
                  <div style={{ marginTop: "20px" }}>
                    <div className="form_xl">
                      <Autocomplete
                        name="company"
                        id="tags-filled"
                        options={companyList.sort(
                          (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                        )}
                        groupBy={(option) => option.firstLetter}
                        getOptionLabel={(option) =>
                          option?.name ? option.name : option
                        }
                        value={
                          selectedCompanyQuery?.name
                            ? selectedCompanyQuery?.name
                            : ""
                        }
                        isOptionEqualToValue={(option, value) => {
                          return (
                            option === "" ||
                            value === option ||
                            value === "" ||
                            option.name === value.name ||
                            option.name === value
                          );
                        }}
                        noOptionsText="No results found. Try advanced search"
                        PaperComponent={({ children }) =>
                          values.company === "" ||
                          selectedCompanyQuery === "" ? (
                            <Paper
                              sx={{
                                "& .MuiAutocomplete-listbox": {
                                  "& .MuiAutocomplete-option[aria-selected='true']":
                                    {
                                      bgcolor: "white.main",
                                    },
                                },
                              }}
                            >
                              {children}
                            </Paper>
                          ) : (
                            <Paper>{children}</Paper>
                          )
                        }
                        onChange={(e, option) => {
                          setFieldValue("company", option ?? "");
                          setSelectedCompanyQuery(option);
                        }}
                        autoHighlight
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              backgroundColor: "white.main",
                              borderBottom: (theme) => `4px solid ${theme.palette.primary.light}`,
                              width: "90%",
                              borderRadius: "3px 3px 0px 0px",
                              fontWeight: "500"
                            }}
                            {...params}
                            variant="standard"
                            label={"Company"}
                          />
                        )}
                      />
                    </div>
                    <Button
                      size="small"
                      style={{
                        backgroundColor: "#3c75b7",
                        fontSize: "16px",
                        height: "fit-content",
                        border: "none",
                        padding: "4px 6px",
                        marginTop: "10px",
                        marginBottom: "30px",
                        width: "90%",
                      }}
                      onClick={() => {
                        setShowCompanyModal(true);
                        setFieldTouched("company");
                        setFieldValue("company", "");
                      }}
                    >
                      Lookup Company
                    </Button>
                  </div>
                ) : null}
                <Select
                variant="standar"
                  defaultValue="none"
                  id="grouped-native-select"
                  name="subjects"
                  style={{ width: "90%" }}
                  disabled={subjectsProposals.length > 0 ? false : true}
                >
                  <option value="none" key="none">
                    Subjects
                  </option>
                  {subjectsProposals.map((parentSubject, indexParent) => {
                    return (
                      <optgroup
                        className="parent-subject-dropdown"
                        key={"-" + indexParent}
                        label={parentSubject.name + "   "}
                      >
                        {parentSubject.subs.map((childSubject, indexChild) => {
                          return (
                            <option
                              className="child-subject-dropdown"
                              key={
                                indexParent.toString() +
                                "-" +
                                indexChild.toString()
                              }
                              value={childSubject}
                            >
                              {childSubject}
                            </option>
                          );
                        })}
                      </optgroup>
                    );
                  })}
                </Select>
              </>
            ) : null}
            {user && (
              <div className="d-flex flex-column mt-4">
                <div className="d-flex flex-row">
                  <CheckBox
                    name="financiallyMaterial"
                    id="fm-yes"
                    label="Financially Material"
                    color="white.main"
                    onClick={() =>
                      setFieldValue(
                        "financiallyMaterial",
                        !values.financiallyMaterial
                      )
                    }
                  />{" "}
                  <InfoTooltip
                  color="white.main"
                    tooltipText={
                      "Select this filter to see votes on ESG proposals that are deemed to be financially material according to the SASB Standards."
                    }
                  />
                </div>
                <div className="d-flex flex-row">
                  <CheckBox
                    name="proponent"
                    id="sh-yes"
                    label="By Shareholder"
                    color="white.main"
                  />{" "}
                  <InfoTooltip
                    color="white.main"
                    tooltipText={
                      "Select this filter if you want to see only those proposals that were filed by shareholders"
                    }
                  />
                </div>
              </div>
            )}

            <div className="form-buttons-box">
              <Button
                type="submit"
                disabled={
                  (!dirty && selectedFunds.length === 0) ||
                  isSubmitting ||
                  (activeGraph === 1 && values.company === "") ||
                  (activeGraph === 2 && values.keywords === "")
                }
              >
                Apply filters
              </Button>
              <Button
                disabled={
                  (!dirty && selectedFunds.length === 0) ||
                  (activeGraph === 1 && values.company === "") ||
                  (activeGraph === 2 && values.keywords === "")
                }
                type="button"
                onClick={(_) => {
                  resetForm();
                  setAllFunds([]);
                }}
              >
                Clear
              </Button>
            </div>
          </FormFormik>
        )}
      </Formik>
      <ModalCompanies
        show={showCompanyModal}
        catchCompanies={(selected) => {
          setSelectedCompanyQuery(selected[0]?.name ? selected[0] : {});
          formikRef.current.setFieldValue("company", selectedCompanyQuery);
          setShowCompanyModal(false);

          const firstLetter = selected[0]?.name[0]?.toUpperCase();
          const newItem = {
            firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
            ...selected[0],
          };
          const isExisting = companyList.find(
            (item) => item._id === newItem._id
          );
          if (!isExisting) {
            setCompanyList((prevList) => [...prevList, newItem]);
          }
        }}
        radioButtons={true}
        isPublicSearch={true}
        dialogClassName="modal-80w"
        handleClose={() => setShowCompanyModal(false)}
        resetSelectedCompaniesinModal={resetSelectedCompaniesinModal}
      />
    </>
  );
};

export default memo(SearchFundsForm);
