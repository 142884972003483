import { Button, Typography } from "@mui/material";
import {
  FormikTextInput as TextField,
  FormikSelect as Select,
} from "../utils/forms";
import countryList from "react-select-country-list";
import { getCompanyIndexList } from "../../utils/utils";
import FormGroup from '@mui/material/FormGroup'; 
import FormControlLabel from '@mui/material/FormControlLabel'; 
import Checkbox from '@mui/material/Checkbox'; 

/**
 *  <p>Component containing the inputs for company search in modals</p>
 * @param {Object} props
 * @param {Function} props.resetForm - function to reset variables from the forms
 * @param {Boolean} props.dirty - indicates if form was filled or not
 * @param {Boolean} props.isSubmitting - indicates if onSubmit function is executing
 * @param {Array} props.industries - list of names of industries to which a company can belong
 * @param {Object} props.auxResetForm - useRef variable to store current status of resetForm
 * @param {Function} props.setNotFoundCompaniesMessage - function to determine whether or not to display a not found message
 * @param {string} props.keyWord - component key name given by the set of Tabs
 * @param {string} props.idName - component name defined in the Modal component
 * @returns rendered view of the component
 * @author milenexeleva
 */
const CompanySearchFields = ({
  resetForm,
  dirty,
  isSubmitting,
  industries,
  auxResetForm,
  setNotFoundCompaniesMessage,
  keyWord,
  idName,
  isFiltersClean,
  setIsFiltersClean,
  clearValues,
  values,
  setFieldValue,
  isPublicSearch
}) => {
  const modalCompaniesFormStyles = {
    labelStyle: "d-none",
    inputSelectStyle: "modal-company-width-auto",
  };
  const countries = countryList().getData();
  // Mostrar el index "S&P 500" y "S&P/TSX 60" en Voting Records
  const companyIndexes = isPublicSearch ? ["S&P 500", "S&P/TSX 60", "S&P/ASX 50"]
    : getCompanyIndexList();

  // Cambio de selección en los checkboxes de forma individual
  /*const handleIndexChange = (e) => {
    const { value } = e.target;

    if (values.index === value) {
      setFieldValue("index", "");
    } else {
      setFieldValue("index", value);
    }
  };*/
  // Cambio de la lógica de selección para ambos checkboxes
  const handleIndexChange = (e) => {
    const { value, checked } = e.target;

    let updatedIndexes = [...(values.index || [])];
    if (checked) {
      if (!updatedIndexes.includes(value)) {
        updatedIndexes.push(value);
      }
    } else {
      updatedIndexes = updatedIndexes.filter((index) => index !== value);
    }
    setFieldValue("index", updatedIndexes);
  };

  // Función para deshabilitar el botón de Search si no hay índices seleccionados
  const isSearchDisabled = () => {
    return isPublicSearch
      ? !(values.index && values.index.length > 0) || isSubmitting
      : false;
  };

  const indexFilter = isPublicSearch ? (
        <FormGroup>
          {companyIndexes.map((indexName) => (
            <FormControlLabel
              key={indexName}
              sx={{"marginBottom":"-1rem"}}
              control={
                <Checkbox
                size="small"
                  name="index"
                  value={indexName}
                  onChange={handleIndexChange}
                  checked={!!(values.index && values.index.includes(indexName))} 
                />
              }
              label={<Typography class="index-checkbox">{indexName}</Typography>}
            />
          ))}
        </FormGroup>
      ) : (
      <Select
        customStyle={{
          ...modalCompaniesFormStyles,
          inputSelectStyle: "modal-company-width-small",
        }}
        name="index"
        value={Array.isArray(values.index) ? values.index[0] || "default" : values.index} 
      >
        <option value="default" key="default">
          --Index
        </option>
        {companyIndexes.map((indexName) => (
          <option value={indexName} key={indexName}>
            {indexName}
          </option>
        ))}
      </Select>
      );

      const filters = isPublicSearch ? (
        <>
      <Select customStyle={modalCompaniesFormStyles} name="industry">
        <option value="default" key="default">
          --Industry
        </option>
        {industries.map((category, index) => {
          if (category.slice(0, 2) !== "--")
            return (
              <option value={category} key={category}>
                {category}
              </option>
            );
          else
            return (
              <option disabled value={category} key={category}>
                {category}
              </option>
            );
        })}
      </Select>

      <Select customStyle={modalCompaniesFormStyles} name="country">
        <option value="default" key="default">
          --Country
        </option>
        {countries.map((category, index) => (
          <option value={category.label} key={category.label}>
            {category.label}
          </option>
        ))}
      </Select>
      {indexFilter}
    </>
  ) : (
    <>
      {indexFilter}
      <Select customStyle={modalCompaniesFormStyles} name="industry">
        <option value="default" key="default">
          --Industry
        </option>
        {industries.map((category, index) => {
          if (category.slice(0, 2) !== "--")
            return (
              <option value={category} key={category}>
                {category}
              </option>
            );
          else
            return (
              <option disabled value={category} key={category}>
                {category}
              </option>
            );
        })}
      </Select>

      <Select customStyle={modalCompaniesFormStyles} name="country">
        <option value="default" key="default">
          --Country
        </option>
        {countries.map((category, index) => (
          <option value={category.label} key={category.label}>
            {category.label}
          </option>
        ))}
      </Select>
      </>
  );

  let selectedFieldContent;
  if (idName === "namesIsin") {
    selectedFieldContent = <TextField name="ISINs" type="text" placeholder="ISIN code" />;
  } else if (idName === "namesForm") {
    selectedFieldContent = (
      <>
        <TextField name="name" type="text" placeholder="Name" />
        {filters}
      </>
    );
  } else {
    selectedFieldContent = (
      <>
        {filters}
      </>
    );
  }

const isDisable = () =>{
  if (!isFiltersClean) return false
  return !dirty || isSubmitting || keyWord === "none"
}

  return (
    <div className="companyFilters_form">
      <Typography component="p" variant="body1">Filter by:</Typography>
      {selectedFieldContent}
      <div className="form-buttons-box">
        <Button
          type="submit"
          sx={{ width: "fit-content" }}
          onClick={() => {
            setIsFiltersClean(false)
            setTimeout(() => {
              auxResetForm.current.resetFunction = resetForm;
              auxResetForm.current.isThereAFormikSelected = true;
            }, 300); // by some unknown reason, it is necessary to wait this time for the auxResetForm variable takes the correct reference to the reset function
          }}
          disabled={isSearchDisabled() || !dirty || isSubmitting}
        >
          Search
        </Button>
        <Button
          variant="light"
          sx={{ width: "fit-content" }}
          type="submit"
          onClick={() => {
            clearValues(values)
            resetForm()
            setNotFoundCompaniesMessage(false);
          }}
          disabled={isDisable()}
        >
          Clear Filters
        </Button>
      </div>
    </div>
  );
};

export default CompanySearchFields;
