import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, Form as FormFormik } from "formik";
import * as Yup from "yup";
import Spinner from "../utils/Spinner";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import CompanySearchFields from "./CompanySearchFields";
import { Typography } from "@mui/material";

/**
 *  <p>Container where options and results from each Tab are shown</p>
 * @param {Object} props
 * @param {string} props.idName - component name defined in the Modal component
 * @param {Function} props.handleSubmit - generates a request to search for companies based on form values
 * @param {Array} props.industries - list of names of industries to which a company can belong
 * @param {Boolean} props.industriesLoading - indicates whether request to get industries is still loading
 * @param {Array} props.companies - list of companies to be shown in each tab based on search results
 * @param {Boolean} props.radioButtons - indicates whether to show radio buttons or checkbox to select the companies
 * @param {Object} props.auxResetForm - useRef variable to store current status of resetForm
 * @param {Boolean} props.notFoundCompaniesMessage - indicates if a not found message should be rendered
 * @param {Function} props.setNotFoundCompaniesMessage - function to determine whether or not to display a not found message
 * @param {Object} props.selectedCompany - variable storing the selected company in case of radio buttons
 * @param {Function} props.sumaSelected - updates variables with currently selected companies
 * @param {Array} props.selecteds - list storing the selected companies in case of checkboxes
 * @param {string} props.keyWord - component key name given by the set of Tabs
 * @returns rendered view of the component
 * @author milenexeleva
 */
const TabResultList = ({
  idName,
  handleSubmit,
  industries,
  industriesLoading,
  companies,
  radioButtons,
  auxResetForm,
  notFoundCompaniesMessage,
  setNotFoundCompaniesMessage,
  selectedCompany,
  sumaSelected,
  selecteds,
  keyWord,
  innerRef,
  isFiltersClean,
  setIsFiltersClean,
  clearValues,
  isPublicSearch
}) => {

  const isinRules = /^[A-Z]{2}[-]{0,1}[0-9A-Z]{9}[-]{0,1}[0-9]{1}$/;
  const validationSchemas = {
    namesForm: Yup.object({
      name: Yup.string().required("Required field"),
    }),
    namesIsin: Yup.object({
      ISINs: Yup.string()
        .min(12, "Must be at least 12 characters")
        .matches(isinRules, { message: "Must be a valid ISIN code" })
        .max(14, "Must be exactly 14 characters")
        .required("ISIN code is required"),
    }),
  };

  const validationSchema = validationSchemas[idName] || Yup.object({});

  const selectionRadioButton = (company) => {
    if (radioButtons) {
      return selectedCompany._id === company._id;
    }
    return selecteds.find((c) => c._id === company._id);
  };

  const idDesignation = (company, index) => {
    if (idName === "namesForm") return `company-${company.name}${index}`;
    else if (idName === "namesIsin") return `company-${company.name}${index}-${idName}`;
    else return `company-${index}-${idName}`;
  };

  const showListCondition =
    idName === "namesForm" ? companies.length > 0 : idName === "namesIsin" ? companies.length > 0 : keyWord === idName;

  return (
    <Formik
      initialValues={ 
        innerRef.current?.initialValues
          ? innerRef.current?.initialValues
          : isPublicSearch? {
            name: "",
            country: "",
            industry: "",
            index: ["S&P 500"],
            ISINs: "",
          }:{
            name: "",
            country: "",
            industry: "",
            index: [],
            ISINs: "",
          }
      }
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          setSubmitting(false);
        }, 400);
        handleSubmit(values);
      }}
      innerRef={innerRef}
    >
      {!industriesLoading ? (
        ({ setFieldValue, errors, touched, resetForm, dirty, isSubmitting, values }) => (
          <FormFormik id={idName}>
            <CompanySearchFields
              {...{
                resetForm,
                dirty,
                isSubmitting,
                industries,
                auxResetForm,
                setNotFoundCompaniesMessage,
                keyWord,
                idName,
                values,
                isFiltersClean,
                setIsFiltersClean,
                clearValues,
                setFieldValue,
                isPublicSearch
              }}
            />
            <ul className="list-names">
              {showListCondition ? (
                companies.map((company, index) => {
                  let key;

                  if (idName === "namesIsin") {
                    key = `company-${index}-${company.name}-${idName}`;
                  } else if (idName === "namesForm") {
                    key = `company-${index}-${company._id}`;
                  } else {
                    key = `company-${index}-${company.name}-${idName}`;
                  }
                  return (
                    <li key={key} >
                      <input
                        type={radioButtons ? "radio" : "checkbox"}
                        value={company.name}
                        checked={selectionRadioButton(company)}
                        onChange={(e) =>
                          sumaSelected(
                            e.target,
                            e.target.value,
                            e.target.checked,
                            company._id
                          )
                        }
                        className="mr-1 names_companies_modal"
                        id={idDesignation(company, index)}
                      />
                      <label htmlFor={idDesignation(company, index)}>
                        {company.name}
                      </label>
                    </li>
                  );
                })
              ) : (
                <></>
              )}
            </ul>
            {notFoundCompaniesMessage && (
              <div className="modal-not-companies-found-container d-flex d-flex flex-column justify-content-center align-items-center">
                <FontAwesomeIcon
                  icon={faFolderOpen}
                  className="icon-not-companies-found"
                />
                <Typography variant="body1" component="p" sx={{
                  fontSize: "20px",
                  marginTop: "5px"}}>
                  No companies were found using the selected criteria 
                </Typography>
              </div>
            )}
          </FormFormik>
        )
      ) : (
        <>
          <Spinner />
        </>
      )}
    </Formik>
  );
};

export default TabResultList;
