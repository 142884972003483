import React, { useState } from "react";
import { mergeProposal } from "../../utils/reqs";
import Box from '@mui/material/Box';
import Button from "react-bootstrap/Button";

/**
 * <p>Modal component for proposal editor to merge proposals</p>
 * <p>Displays a table to select a proposal to merge</p>
 * @param {Object} props
 * @param {Array} props.errorsInproposalsToMerge - list of errors to validate the selected proposals to merge
 * @param {function} props.closeModal - closes the modal
 * @param {Array} props.proposalsToMerge - list of the proposals to merge
 * @param {function} props.updateSelectedData - updates the reference of the selected proposals
 * @param {Object} props.user - object with the information of the logged user
 * @param {Object} props.formikRef - reference to form in side menu
 * @param {function} props.searchProposals - searches for paginated proposals
 * @param {number} props.page - current page of the results
 * @param {function} props.setLoading - sets loading state
 * @returns Rendered view of modal to merge proposals
 * @author Medina192
 * @author valeriaxeleva
 */
const ModalMergeProposals = ({ errorsInproposalsToMerge, closeModal, proposalsToMerge,
    updateSelectedData, user, formikRef,
    searchProposals, page, setLoading }) => {

    const [indexProposalSelected, setIndexProposalSelected] = useState(-1);

    //const widthModal = 850;
    const widthModal = window.innerWidth > 768 ? "60%" : "90%";
    const heightModal = 500;
    const modalStyle = {
        width: widthModal,
        height: errorsInproposalsToMerge.length > 0 ? "auto" : heightModal,
    };

    const handleSubmitMergeProposal = async () => {
        setLoading(true);

        const body = {
            indexesToMerge: [],
            indexSelected: "",
            meeting_id: ""
        }

        for (let i = 0; i < proposalsToMerge.length; i++) {
            if (i === indexProposalSelected) {
                body.meeting_id = proposalsToMerge[indexProposalSelected]._id;
                body.indexSelected = proposalsToMerge[indexProposalSelected];
            } else {
                body.indexesToMerge.push(proposalsToMerge[i])
            }
        }
        body.username = user?.email;

        mergeProposal(body)
            .then(() => {
                updateSelectedData();
                closeModal();
                alert("The proposals were merged succesfully");
                const valuesForm = formikRef.current.values;
                const nonFalsyValues = Object
                    .keys(valuesForm)
                    .filter(key => {
                        const cond1 = typeof valuesForm[key] === 'string' && valuesForm[key] !== '';
                        const cond2 = typeof valuesForm[key] === 'object' && !!valuesForm[key].length;
                        return cond1 || cond2;
                    })

                const bodySearch = {};
                nonFalsyValues.forEach(key => bodySearch[key] = valuesForm[key]);
                bodySearch.editMode = true;
                bodySearch.page = page;
                const { funds, ...rest } = bodySearch;
                searchProposals(rest);
            })
            .catch((error) => {
                alert(error.message);
            })
            .finally(() => { setLoading(false) });
    }

    return (
        <Box sx={modalStyle}>
            {
                errorsInproposalsToMerge.length > 0 ?
                    errorsInproposalsToMerge.map((errorDescription, index) => {
                        return (
                            <p key={index + "errors-replace"} style={{ color: "#b00", fontSize: "18px", margintTop: "10px" }}>{errorDescription}</p>
                        )
                    })
                    : <>
                        <p>Select the proposal to preserve</p>
                        <div style={{ maxHeight: "85%", overflow: "scroll" }}>
                            <table className="table table-striped" style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th> </th>
                                        <th>Description</th>
                                        <th>Management</th>
                                        <th>Proponent</th>
                                        <th>antiESG</th>
                                        <th>Subject</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        proposalsToMerge.map((proposal, indexProposal) => {

                                            return (
                                                <tr style={{ padding: "5px" }} key={proposal._id}>
                                                    <td className="cell" style={{ width: "5%" }}>
                                                        <input
                                                            onClick={(value) => {
                                                                setIndexProposalSelected(indexProposal);
                                                            }}
                                                            type="radio"
                                                            id={indexProposal}
                                                            checked={indexProposalSelected === indexProposal}
                                                        />
                                                    </td>
                                                    <td style={{ width: "45%" }} >{proposal.description} </td>
                                                    <td style={{ width: "10%" }}>{proposal.managementRecommendation}</td>
                                                    <td style={{ width: "15%" }}>{proposal.proponent}</td>
                                                    <td style={{ width: "15%" }}>{proposal.antiESG}</td>
                                                    <td style={{ width: "25%" }}>{proposal.subjects?.subject ? proposal.subjects?.subject : ""}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </>}
            <div className="d-flex justify-content-end w-100">
                <Button className="m-2" disabled={errorsInproposalsToMerge.length > 0 && indexProposalSelected <= -1} variant="success" onClick={handleSubmitMergeProposal}>Merge</Button>

            </div>
        </Box>


    )
}

export default ModalMergeProposals;
